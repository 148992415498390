import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://562a4dbb3c3a018c46da4d3c90a21ec6@o4507909706940416.ingest.us.sentry.io/4507909708185600",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});